/**
 * @module ol/render/webgl/polygonreplay/defaultshader/Locations
 */
// This file is automatically generated, do not edit
// Run `make shaders` to generate, and commit the result.

import {DEBUG as DEBUG_WEBGL} from '../../../../webgl.js';

/**
 * @constructor
 * @param {WebGLRenderingContext} gl GL.
 * @param {WebGLProgram} program Program.
 * @struct
 */
const Locations = function(gl, program) {

  /**
   * @type {WebGLUniformLocation}
   */
  this.u_projectionMatrix = gl.getUniformLocation(
    program, DEBUG_WEBGL ? 'u_projectionMatrix' : 'b');

  /**
   * @type {WebGLUniformLocation}
   */
  this.u_offsetScaleMatrix = gl.getUniformLocation(
    program, DEBUG_WEBGL ? 'u_offsetScaleMatrix' : 'c');

  /**
   * @type {WebGLUniformLocation}
   */
  this.u_offsetRotateMatrix = gl.getUniformLocation(
    program, DEBUG_WEBGL ? 'u_offsetRotateMatrix' : 'd');

  /**
   * @type {WebGLUniformLocation}
   */
  this.u_color = gl.getUniformLocation(
    program, DEBUG_WEBGL ? 'u_color' : 'e');

  /**
   * @type {WebGLUniformLocation}
   */
  this.u_opacity = gl.getUniformLocation(
    program, DEBUG_WEBGL ? 'u_opacity' : 'f');

  /**
   * @type {number}
   */
  this.a_position = gl.getAttribLocation(
    program, DEBUG_WEBGL ? 'a_position' : 'a');
};

export default Locations;
